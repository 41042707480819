@font-face {
  font-family: 'Gelasio';
  src: url('../fonts/Gelasio/Gelasio-Regular.eot'); /* IE9 Compat Modes */
  src: url('../fonts/Gelasio/Gelasio-Regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/Gelasio/Gelasio-Regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/Gelasio/Gelasio-Regular.woff') format('woff'),
    /* Pretty Modern Browsers */ url('../fonts/Gelasio/Gelasio-Regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../fonts/Gelasio/Gelasio-Regular.svg#Gelasio') format('svg'); /* Legacy iOS */
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Gelasio';
  src: url('../fonts/Gelasio/Gelasio-Italic.eot'); /* IE9 Compat Modes */
  src: url('../fonts/Gelasio/Gelasio-Italic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/Gelasio/Gelasio-Italic.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/Gelasio/Gelasio-Italic.woff') format('woff'),
    /* Pretty Modern Browsers */ url('../fonts/Gelasio/Gelasio-Italic.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../fonts/Gelasio/Gelasio-Italic.svg#Gelasio') format('svg'); /* Legacy iOS */
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Gelasio';
  src: url('../fonts/Gelasio/Gelasio-Medium.eot'); /* IE9 Compat Modes */
  src: url('../fonts/Gelasio/Gelasio-Medium.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/Gelasio/Gelasio-Medium.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/Gelasio/Gelasio-Medium.woff') format('woff'),
    /* Pretty Modern Browsers */ url('../fonts/Gelasio/Gelasio-Medium.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../fonts/Gelasio/Gelasio-Medium.svg#Gelasio') format('svg'); /* Legacy iOS */
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Gelasio';
  src: url('../fonts/Gelasio/Gelasio-MediumItalic.eot'); /* IE9 Compat Modes */
  src: url('../fonts/Gelasio/Gelasio-MediumItalic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/Gelasio/Gelasio-MediumItalic.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/Gelasio/Gelasio-MediumItalic.woff') format('woff'),
    /* Pretty Modern Browsers */ url('../fonts/Gelasio/Gelasio-MediumItalic.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../fonts/Gelasio/Gelasio-MediumItalic.svg#Gelasio') format('svg'); /* Legacy iOS */
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Gelasio';
  src: url('../fonts/Gelasio/Gelasio-SemiBold.eot'); /* IE9 Compat Modes */
  src: url('../fonts/Gelasio/Gelasio-SemiBold.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/Gelasio/Gelasio-SemiBold.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/Gelasio/Gelasio-SemiBold.woff') format('woff'),
    /* Pretty Modern Browsers */ url('../fonts/Gelasio/Gelasio-SemiBold.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../fonts/Gelasio/Gelasio-SemiBold.svg#Gelasio') format('svg'); /* Legacy iOS */
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Gelasio';
  src: url('../fonts/Gelasio/Gelasio-SemiBoldItalic.eot'); /* IE9 Compat Modes */
  src: url('../fonts/Gelasio/Gelasio-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/Gelasio/Gelasio-SemiBoldItalic.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/Gelasio/Gelasio-SemiBoldItalic.woff') format('woff'),
    /* Pretty Modern Browsers */ url('../fonts/Gelasio/Gelasio-SemiBoldItalic.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../fonts/Gelasio/Gelasio-SemiBoldItalic.svg#Gelasio') format('svg'); /* Legacy iOS */
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Gelasio';
  src: url('../fonts/Gelasio/Gelasio-Bold.eot'); /* IE9 Compat Modes */
  src: url('../fonts/Gelasio/Gelasio-Bold.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/Gelasio/Gelasio-Bold.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/Gelasio/Gelasio-Bold.woff') format('woff'),
    /* Pretty Modern Browsers */ url('../fonts/Gelasio/Gelasio-Bold.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../fonts/Gelasio/Gelasio-Bold.svg#Gelasio') format('svg'); /* Legacy iOS */
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Gelasio';
  src: url('../fonts/Gelasio/Gelasio-BoldItalic.eot'); /* IE9 Compat Modes */
  src: url('../fonts/Gelasio/Gelasio-BoldItalic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/Gelasio/Gelasio-BoldItalic.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/Gelasio/Gelasio-BoldItalic.woff') format('woff'),
    /* Pretty Modern Browsers */ url('../fonts/Gelasio/Gelasio-BoldItalic.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../fonts/Gelasio/Gelasio-BoldItalic.svg#Gelasio') format('svg'); /* Legacy iOS */
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Raleway';
  src: url('../fonts/Raleway/Raleway-Thin.eot'); /* IE9 Compat Modes */
  src: url('../fonts/Raleway/Raleway-Thin.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/Raleway/Raleway-Thin.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/Raleway/Raleway-Thin.woff') format('woff'),
    /* Pretty Modern Browsers */ url('../fonts/Raleway/Raleway-Thin.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../fonts/Raleway/Raleway-Thin.svg#Raleway') format('svg'); /* Legacy iOS */
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('../fonts/Raleway/Raleway-ThinItalic.eot'); /* IE9 Compat Modes */
  src: url('../fonts/Raleway/Raleway-ThinItalic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/Raleway/Raleway-ThinItalic.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/Raleway/Raleway-ThinItalic.woff') format('woff'),
    /* Pretty Modern Browsers */ url('../fonts/Raleway/Raleway-ThinItalic.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../fonts/Raleway/Raleway-ThinItalic.svg#Raleway') format('svg'); /* Legacy iOS */
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Raleway';
  src: url('../fonts/Raleway/Raleway-ExtraLight.eot'); /* IE9 Compat Modes */
  src: url('../fonts/Raleway/Raleway-ExtraLight.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/Raleway/Raleway-ExtraLight.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/Raleway/Raleway-ExtraLight.woff') format('woff'),
    /* Pretty Modern Browsers */ url('../fonts/Raleway/Raleway-ExtraLight.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../fonts/Raleway/Raleway-ExtraLight.svg#Raleway') format('svg'); /* Legacy iOS */
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('../fonts/Raleway/Raleway-ExtraLightItalic.eot'); /* IE9 Compat Modes */
  src: url('../fonts/Raleway/Raleway-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/Raleway/Raleway-ExtraLightItalic.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/Raleway/Raleway-ExtraLightItalic.woff') format('woff'),
    /* Pretty Modern Browsers */ url('../fonts/Raleway/Raleway-ExtraLightItalic.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../fonts/Raleway/Raleway-ExtraLightItalic.svg#Raleway') format('svg'); /* Legacy iOS */
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Raleway';
  src: url('../fonts/Raleway/Raleway-Light.eot'); /* IE9 Compat Modes */
  src: url('../fonts/Raleway/Raleway-Light.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/Raleway/Raleway-Light.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/Raleway/Raleway-Light.woff') format('woff'),
    /* Pretty Modern Browsers */ url('../fonts/Raleway/Raleway-Light.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../fonts/Raleway/Raleway-Light.svg#Raleway') format('svg'); /* Legacy iOS */
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('../fonts/Raleway/Raleway-LightItalic.eot'); /* IE9 Compat Modes */
  src: url('../fonts/Raleway/Raleway-LightItalic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/Raleway/Raleway-LightItalic.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/Raleway/Raleway-LightItalic.woff') format('woff'),
    /* Pretty Modern Browsers */ url('../fonts/Raleway/Raleway-LightItalic.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../fonts/Raleway/Raleway-LightItalic.svg#Raleway') format('svg'); /* Legacy iOS */
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Raleway';
  src: url('../fonts/Raleway/Raleway-Regular.eot'); /* IE9 Compat Modes */
  src: url('../fonts/Raleway/Raleway-Regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/Raleway/Raleway-Regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/Raleway/Raleway-Regular.woff') format('woff'),
    /* Pretty Modern Browsers */ url('../fonts/Raleway/Raleway-Regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../fonts/Raleway/Raleway-Regular.svg#Raleway') format('svg'); /* Legacy iOS */
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('../fonts/Raleway/Raleway-Italic.eot'); /* IE9 Compat Modes */
  src: url('../fonts/Raleway/Raleway-Italic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/Raleway/Raleway-Italic.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/Raleway/Raleway-Italic.woff') format('woff'),
    /* Pretty Modern Browsers */ url('../fonts/Raleway/Raleway-Italic.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../fonts/Raleway/Raleway-Italic.svg#Raleway') format('svg'); /* Legacy iOS */
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Raleway';
  src: url('../fonts/Raleway/Raleway-Medium.eot'); /* IE9 Compat Modes */
  src: url('../fonts/Raleway/Raleway-Medium.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/Raleway/Raleway-Medium.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/Raleway/Raleway-Medium.woff') format('woff'),
    /* Pretty Modern Browsers */ url('../fonts/Raleway/Raleway-Medium.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../fonts/Raleway/Raleway-Medium.svg#Raleway') format('svg'); /* Legacy iOS */
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('../fonts/Raleway/Raleway-MediumItalic.eot'); /* IE9 Compat Modes */
  src: url('../fonts/Raleway/Raleway-MediumItalic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/Raleway/Raleway-MediumItalic.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/Raleway/Raleway-MediumItalic.woff') format('woff'),
    /* Pretty Modern Browsers */ url('../fonts/Raleway/Raleway-MediumItalic.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../fonts/Raleway/Raleway-MediumItalic.svg#Raleway') format('svg'); /* Legacy iOS */
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Raleway';
  src: url('../fonts/Raleway/Raleway-SemiBold.eot'); /* IE9 Compat Modes */
  src: url('../fonts/Raleway/Raleway-SemiBold.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/Raleway/Raleway-SemiBold.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/Raleway/Raleway-SemiBold.woff') format('woff'),
    /* Pretty Modern Browsers */ url('../fonts/Raleway/Raleway-SemiBold.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../fonts/Raleway/Raleway-SemiBold.svg#Raleway') format('svg'); /* Legacy iOS */
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('../fonts/Raleway/Raleway-SemiBoldItalic.eot'); /* IE9 Compat Modes */
  src: url('../fonts/Raleway/Raleway-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/Raleway/Raleway-SemiBoldItalic.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/Raleway/Raleway-SemiBoldItalic.woff') format('woff'),
    /* Pretty Modern Browsers */ url('../fonts/Raleway/Raleway-SemiBoldItalic.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../fonts/Raleway/Raleway-SemiBoldItalic.svg#Raleway') format('svg'); /* Legacy iOS */
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Raleway';
  src: url('../fonts/Raleway/Raleway-Bold.eot'); /* IE9 Compat Modes */
  src: url('../fonts/Raleway/Raleway-Bold.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/Raleway/Raleway-Bold.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/Raleway/Raleway-Bold.woff') format('woff'),
    /* Pretty Modern Browsers */ url('../fonts/Raleway/Raleway-Bold.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../fonts/Raleway/Raleway-Bold.svg#Raleway') format('svg'); /* Legacy iOS */
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('../fonts/Raleway/Raleway-BoldItalic.eot'); /* IE9 Compat Modes */
  src: url('../fonts/Raleway/Raleway-BoldItalic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/Raleway/Raleway-BoldItalic.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/Raleway/Raleway-BoldItalic.woff') format('woff'),
    /* Pretty Modern Browsers */ url('../fonts/Raleway/Raleway-BoldItalic.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../fonts/Raleway/Raleway-BoldItalic.svg#Raleway') format('svg'); /* Legacy iOS */
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Raleway';
  src: url('../fonts/Raleway/Raleway-ExtraBold.eot'); /* IE9 Compat Modes */
  src: url('../fonts/Raleway/Raleway-ExtraBold.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/Raleway/Raleway-ExtraBold.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/Raleway/Raleway-ExtraBold.woff') format('woff'),
    /* Pretty Modern Browsers */ url('../fonts/Raleway/Raleway-ExtraBold.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../fonts/Raleway/Raleway-ExtraBold.svg#Raleway') format('svg'); /* Legacy iOS */
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('../fonts/Raleway/Raleway-ExtraBoldItalic.eot'); /* IE9 Compat Modes */
  src: url('../fonts/Raleway/Raleway-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/Raleway/Raleway-ExtraBoldItalic.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/Raleway/Raleway-ExtraBoldItalic.woff') format('woff'),
    /* Pretty Modern Browsers */ url('../fonts/Raleway/Raleway-ExtraBoldItalic.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../fonts/Raleway/Raleway-ExtraBoldItalic.svg#Raleway') format('svg'); /* Legacy iOS */
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Raleway';
  src: url('../fonts/Raleway/Raleway-Black.eot'); /* IE9 Compat Modes */
  src: url('../fonts/Raleway/Raleway-Black.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/Raleway/Raleway-Black.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/Raleway/Raleway-Black.woff') format('woff'),
    /* Pretty Modern Browsers */ url('../fonts/Raleway/Raleway-Black.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../fonts/Raleway/Raleway-Black.svg#Raleway') format('svg'); /* Legacy iOS */
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('../fonts/Raleway/Raleway-BlackItalic.eot'); /* IE9 Compat Modes */
  src: url('../fonts/Raleway/Raleway-BlackItalic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/Raleway/Raleway-BlackItalic.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/Raleway/Raleway-BlackItalic.woff') format('woff'),
    /* Pretty Modern Browsers */ url('../fonts/Raleway/Raleway-BlackItalic.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../fonts/Raleway/Raleway-BlackItalic.svg#Raleway') format('svg'); /* Legacy iOS */
  font-weight: 900;
  font-style: italic;
}